import { Delete, Edit, EventRepeatTwoTone, InfoSharp } from '@mui/icons-material';
import { Button, IconButton, TablePagination } from '@mui/material';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { useContext, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';
import { useModal } from '../context/ModalProvider';
import { IMember, IPrivateService } from '../types';
import { getLocalizedErrorMessage } from '../utils/functions';
import { format } from 'date-fns';
export type IPrivateServiceAppointment = {
    time: Date;
    service: IPrivateService;
    _id: string;
    followup?: string;
    followup_of?: string;
    price?: number;
    member: IMember;
    first_name: string;
    last_name: string;
    payment_confirm: boolean;
    cancellable: boolean;
    subsidized: boolean;
    duration: number;
    email: string;
    phone: string;
    schedule_another: boolean;
};
type PrivateSessionTableRowProps = {
    session: IPrivateServiceAppointment;
    onPrivateSessionSetAppointment: (member_id: string, member_name: string, service: any) => void;
    refetchFunction: () => Promise<void>;
};

const Row = ({ session, onPrivateSessionSetAppointment, refetchFunction }: PrivateSessionTableRowProps) => {
    const alert = useContext(AlertContext);
    const modal = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const handleCancelSession = async (session_id: string) => {
        try {
            setIsLoading(true);
            modal.hide();
            await axios.delete(`${constants.API_URL}/member/private_service/appointment/`, {
                data: { slot_id: session_id, silent: false },
            });
            alert?.showAlert('המפגש בוטל', 'success');

            await refetchFunction();
        } catch (error) {
            alert?.showAlert(getLocalizedErrorMessage(error as AxiosError, 'he'), 'error');
        } finally {
            setIsLoading(false);
        }
    };
    const handleRescheduleSession = async (session_id: string, new_time: Date) => {
        try {
            setIsLoading(true);
            modal.hide();
            await axios.put(`${constants.API_URL}/instructor/admin/private_session`, {
                new_time: new_time,
                old_slot_id: session_id,
            });
            alert?.showAlert('המפגש עודכן', 'success');

            await refetchFunction();
        } catch (error) {
            alert?.showAlert(getLocalizedErrorMessage(error as AxiosError, 'he'), 'error');
        } finally {
            setIsLoading(false);
        }
    };
    const showCancelDialog = async (session_id: string) => {
        modal.show({
            title: 'בטוח/ה?',
            body: 'האם את/ה בטוח/ה שברצונך לבטל את הפגישה?',
            footer: (
                <div className='flex flex-row justify-between w-full'>
                    <Button onClick={() => handleCancelSession(session_id)} color='error'>
                        כן
                    </Button>
                    <Button onClick={modal.hide}>לא</Button>
                </div>
            ),
        });
    };
    const showRescheduleModal = (session_id: string, session: IPrivateServiceAppointment) => {
        let new_time: Date | null = null;
        modal.show({
            title: 'הזזת מפגש',
            body: (
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col items-center'>
                        <span>
                            בחר/י תאריך ושעה חדשים למפגש <br />
                        </span>
                        <label className='line-through text-center mx-auto'>{format(session.time, 'dd/MM/yy hh:mm')}</label>
                    </div>
                    <input
                        className='p-1'
                        type='datetime-local'
                        onChange={(e) => {
                            new_time = new Date(e.target.value);
                            console.log('new_time', new_time);
                        }}
                    />
                    <button
                        className='px-2 py-1 bg-blue-500 hover:bg-blue-600 rounded-lg font-medium text-white disabled:pointer-events-none disabled:opacity-20'
                        onClick={() => {
                            if (new_time) handleRescheduleSession(session_id, new_time);
                        }}>
                        הזזת פגישה
                    </button>
                </div>
            ),
        });
    };
    const maskedName = `${session.first_name?.slice(0, 10)} ${session.last_name?.slice(0, 1) + '.'}`;
    const member = session.member;
    const service = session.service;
    const moreInfo = [
        { title: 'שם', value: `${session.first_name} ${session.last_name}` },
        { title: 'מייל', value: session?.email },
        { title: 'תאריך', value: `${moment(session.time).format('DD/MM HH:mm')} (${moment(session.time).fromNow()})` },
        { title: 'טלפון', value: <span dir='ltr'> {session?.phone}</span> },
        { title: 'מסובסד', value: session?.subsidized ? 'כן' : 'לא' },
        { title: 'שיחת המשך', value: session.followup_of ? 'כן' : 'לא' },
        { title: 'משך', value: `${session.duration} דקות` },

        {
            title: 'סטטוס',
            value: <span className={`font-bold ${session.payment_confirm ? 'text-green-700' : 'text-red-700'}`}>{session.payment_confirm !== true ? 'ממתין לתשלום' : 'שולם'}</span>,
        },
    ];
    const controls = ({ hideInfoButton }: { hideInfoButton?: boolean }) => (
        <div className='flex flex-col justify-center'>
            <div className='flex flex-row gap-2'>
                {!hideInfoButton && (
                    <IconButton
                        onClick={() => {
                            modal.show({
                                title: `${service.title.he} עם ${maskedName}`,
                                body: (
                                    <table dir='rtl' className='border-spacing-x-4 border-spacing-y-2 border-separate w-full'>
                                        {moreInfo.map((row) => (
                                            <tr key={row.title}>
                                                <td>{`${row.title}:`}</td>
                                                <td className='font-semibold'>{row.value}</td>
                                            </tr>
                                        ))}
                                    </table>
                                ),
                                footer: <div className='flex flex-row justify-left w-full'>{controls({ hideInfoButton: true })}</div>,
                            });
                        }}
                        color='primary'
                        title='מידע נוסף'>
                        <InfoSharp />
                    </IconButton>
                )}
                <IconButton
                    onClick={() => {
                        onPrivateSessionSetAppointment(session.member?._id, session?.first_name, session.service);
                    }}
                    color='info'
                    disabled={!session.schedule_another}
                    title='פגישה נוספת'>
                    <EventRepeatTwoTone />
                </IconButton>
                <IconButton
                    onClick={() => {
                        showRescheduleModal(session._id, session);
                    }}
                    color='info'
                    disabled={!session.cancellable}
                    title='הזזת מפגש'>
                    <Edit />
                </IconButton>
                <IconButton
                    disabled={!session.cancellable}
                    onClick={() => {
                        showCancelDialog(session._id);
                    }}
                    color='warning'
                    title='ביטול פגישה'>
                    <Delete />
                </IconButton>
            </div>
        </div>
    );
    return (
        <>
            <div
                className={twMerge(
                    `first:border-t w-full transition-all text-right flex flex-row justify-between px-4 border-b py-1.5`,
                    moment(session.time).isAfter(new Date()) ? 'bg-gradient-to-l from-green-100 to-white hover:to-green-50' : 'hover:bg-gray-100',
                    isLoading ? 'animate-pulse' : ''
                )}>
                <div className='flex  flex-col gap-1 w-1/2'>
                    <div className='flex flex-row gap-6 items-center'>
                        <span className='font-semibold text-lg'>{maskedName}</span>
                        <span className=''>{`${member?.company?.english_name || ''}`}</span>
                        {!session.payment_confirm ? <span className='text-red-700 font-bold'>טרם אושר</span> : null}
                    </div>
                    <div className='flex flex-row gap-x-8 justify-between text-sm'>
                        <span className='font-semibold'>{moment(session.time).format('DD/MM/YY HH:mm')}</span>
                        <span className='text-right flex flex-1'>{`${service?.title?.he} ${session.followup_of ? '(פגישת המשך)' : ''}`}</span>
                        <span style={{ minWidth: 60, whiteSpace: 'nowrap' }}>{`${session?.price ? `${session.price || 0} ₪` : ''}`}</span>
                        <span style={{ minWidth: 160, whiteSpace: 'nowrap' }}>{session?.member?._id ? 'מנוי/ת GroupHug' : ''}</span>
                    </div>
                </div>
                {controls({})}
            </div>
        </>
    );
};

type PrivateSessionsTableProps = {
    privateSessions?: IPrivateServiceAppointment[];
    onPrivateSessionSetAppointment: (member_id: string, member_name: string, service: IPrivateService) => void;
    isPsychologist?: boolean;
    refetchFunction: () => Promise<void>;
    loading: boolean;
};
const PrivateSessionsTable = ({ privateSessions = [], onPrivateSessionSetAppointment, refetchFunction }: PrivateSessionsTableProps) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    return (
        <div>
            <div className='px-4 flex flex-col gap-1 mb-2'>
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <span>לקבלת פרטים נוספים על הפגישה לחצו על </span>
                    <InfoSharp color={'primary'} />
                    <span>בשורה המתאימה</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <span>להזזת פגישה שנקבעה לחצו על </span>
                    <Edit color={'info'} />
                    <span>בשורה המתאימה</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <span>לקביעת פגישה חוזרת לחצו על </span>
                    <EventRepeatTwoTone color={'info'} />
                    <span>בשורה המתאימה</span>
                </div>
                <div>
                    <span>מפגשים עתידיים מסומנים</span>
                    <span className='text-green-600 font-bold'>{' בצבע ירוק'}</span>
                </div>
            </div>
            <div>
                <div>
                    <div className='w-full flex flex-col gap-1'>
                        {privateSessions
                            .sort((a, b) => moment(b.time).diff(a.time))
                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                            .map((session) => (
                                <Row
                                    key={`session#${session?._id}`}
                                    session={session}
                                    onPrivateSessionSetAppointment={onPrivateSessionSetAppointment}
                                    refetchFunction={refetchFunction}
                                />
                            ))}
                    </div>

                    <TablePagination
                        backIconButtonProps={{ style: { transform: 'rotate(180deg)' } }}
                        nextIconButtonProps={{ style: { transform: 'rotate(180deg)' } }}
                        component='div'
                        count={privateSessions.length}
                        page={page}
                        onPageChange={(e, newPage) => {
                            setPage(newPage);
                        }}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PrivateSessionsTable;
